
<template>
	<div class="main-content pb-5">
		<ktv-breadcrumb title="Add Training" page="Add Training" folder="Training" />
		<ktv-back @click="backToList()" />
		<b-row class="mt-3">
			<b-col lg="7" sm="12" md="12" xs="12">
				<b-card border-variant="primary" header="Training Basic Info" header-bg-variant="primary" header-text-variant="white">
					<vcl-list v-show="loadingHeader" />
					<b-card-text v-show="!loadingHeader">
						<b-form>
							<b-row>
								<b-form-group class="col-md-6">
									Category <span class="text-danger">*</span>
									<b-form-select
										v-model="trainingHeaders.category"
										v-validate="'required'"
										:options="categories"
										name="category"
										data-vv-scope="training-basic"
										:state="validateState('category')"
										@change="getSubCategory(trainingHeaders.category)"
									/>
									<b-form-invalid-feedback>{{ veeErrors.first("training-basic.category") }}</b-form-invalid-feedback>
								</b-form-group>
								<b-form-group class="col-md-6">
									Sub Category <span class="text-danger">*</span>
									<b-form-select
										v-model="trainingHeaders.subCategory"
										v-validate="'required'"
										:options="subCategories"
										:disabled="!trainingHeaders.category"
										name="sub category"
										data-vv-scope="training-basic"
										:state="validateState('sub category')"
									/>
									<b-form-invalid-feedback>{{ veeErrors.first("training-basic.sub category") }}</b-form-invalid-feedback>
								</b-form-group>
							</b-row>
						</b-form>
						<ktv-button
							class="mt-2"
							text="Commodity"
							size="sm"
							:disabled="!canAddCommodity"
							color="success"
							icon="add"
							@click="openFormCommodity"
						/>
						<div v-if="loadingCommodityTable" class="mt-3">
							<b-skeleton-table :rows="3" :columns="4" :table-props="{ bordered: true, striped: true }" />
						</div>
						<ktv-table
							v-else-if="commodityTable.rows.length && !loadingCommodityTable"
							:columns="commodityTable.columns"
							:is-loading="tableLoad"
							:line-numbers="true"
							:rows="commodityTable.rows"
							:filter="false"
							:search-options="{
								enabled: false,
							}"
							class="mt-3"
						>
							<template #rows="{ props }">
								<span v-if="props.column.field == 'button'">
									<b-dropdown
										size="xs"
										variant="link"
										toggle-class="text-decoration-none"
										no-caret
									>
										<template #button-content>
											<span class="btndropdownaction">
												<img :src="icondropdown">
											</span>
										</template>
										<b-dropdown-item class="dropdownitem-action" @click="openFormCommodity(props.row)">
											<img :src="iconedit" class="imgicon"> Edit
										</b-dropdown-item>
										<b-dropdown-item class="dropdownitem-action" @click="deleteCommodity(props.row)">
											<img :src="icondelete" class="imgicon"> Delete
										</b-dropdown-item>
									</b-dropdown>
								</span>
								<span v-else-if="props.column.field == 'partnerAccess'">
									<span v-if="props.row.partnerAccess.length">
										<b-badge v-for="(v, i) in props.row.partnerAccess" :key="i" href="#" variant="primary">{{ v.PartnerName }}</b-badge>
									</span>
									<span v-else>
										<b-badge variant="warning">No Partner</b-badge>
									</span>
								</span>
								<span v-else-if="props.column.field == 'id'">
									<span>{{ getCommoditiyName(props.row.id) }} </span>
								</span>
							</template>
						</ktv-table>
						<div class="w-100 mt-3 text-right">
							<span v-show="!commodityTable.rows.length" class="text-warning mr-3">(* Add Commodity First)</span>
							<ktv-button
								text="Save"
								:loading="loading"
								color="green-dark"
								icon="save"
								:disabled="!canAddCommodity || !canSaveTrainingHeader"
								@click="saveTrainingHeader()"
							/>
						</div>
					</b-card-text>
				</b-card>
				<div v-if="trainingId">
					<b-card class="mt-3">
						<vcl-facebook v-show="loadingTraining" />
						<b-tabs v-show="!loadingTraining" content-class="mt-3">
							<b-tab v-for="(v, i) in training" :key="i" @click="changeTab(i)">
								<template #title>
									{{ getLanguageName(v.TrainingListLanguageCode) }}
									<b-icon v-show="v.TrainingListLanguageCode !== 'in'" icon="x" class="cursor-pointer" @click="deleteLanguageTab(i)" />
								</template>
								<b-row role="group">
									<b-form-group class="col-md-12">
										Title <span class="text-danger">*</span>
										<b-form-input
											v-model="training[i].TrainingListLanguageTitle"
											v-validate="'required'"
											:name="`title-` + i"
											data-vv-as="title "
											:state="validateState('title-' + i)"
											size="sm"
										/>
										<b-form-invalid-feedback>{{ veeErrors.first("title-" + i) }}</b-form-invalid-feedback>
									</b-form-group>
									<b-form-group class="col-md-6">
										Sub Title <span class="text-danger">*</span>
										<b-form-input
											v-model="training[i].TrainingListLanguageSubTitle"
											v-validate="'required'"
											size="sm"
											:name="`subtitle-` + i"
											data-vv-as="sub title"
											:state="validateState('subtitle-' + i)"
										/>
										<b-form-invalid-feedback>{{ veeErrors.first("subtitle-" + i) }}</b-form-invalid-feedback>
									</b-form-group>
									<b-form-group class="col-md-6">
										<span>Main Image</span><span class="text-danger"> * </span> <br>
										<span class="image-label">(All images in all languages will be changed)</span>
										<b-file
											ref="imageMain"
											size="sm"
											:placeholder="trainingImage || `Choose a file ..`"
											:disabled="i !== 0"
											@click.native="resetImageUploader('training_image')"
											@change="uploadImage($event, 'training_image')"
										/>
										<span v-if="trainingImage"><a :href="trainingImage" target="_blank">Uploaded Image <b-icon icon="file-image" /> <b-icon icon="check-circle-fill" /></a>
										</span>
										<span v-else class="image-label">ext: .jpg, .png (Max: 1024Kb)</span>
									</b-form-group>
									<b-form-group class="col-md-12">
										Training Description <span class="text-danger">*</span>
										<ckeditor
											v-model="training[i].TrainingListLanguageDescription"
											v-validate="'required'"
											:editor="editor"
											:config="editorConfig"
											:name="`description-` + i"
											data-vv-as="description"
											:state="validateState('description-' + i)"
											class="form-control"
										/>
										<small class="text-danger">{{ veeErrors.first("description-" + i) }}</small>
									</b-form-group>
								</b-row>
								<div v-if="training[i].detail.length">
									<div
										v-for="(v2, i2) in training[i].detail"
										:key="i2"
										class="accordion mt-3"
										role="tablist"
										:class="{ 'border-color': '' + i + i2 == trainingDetailId }"
									>
										<b-card no-body @click="loadCanvas(v2, i, i2)">
											<b-card-header header-tag="header" role="tab">
												<div v-b-toggle="'accordion-' + i + i2" block class="row justify-content-between cursor-pointer">
													<b-col cols="auto" class="mr-auto p-2 d-flex">
														<a style="font-size: 18px">
															<b-icon icon="three-dots-vertical" />Page<span class="text-danger">* </span>
														</a>
														<b-form-input
															v-model="training[i].detail[i2].TrainingDetailPageNo"
															v-validate="'required'"
															class="col-4 ml-1"
															type="number"
															:name="`page-number-` + i + i2"
															data-vv-as="page number"
															:state="validateState('page-number-' + i + i2)"
															:disabled="i !== 0"
															@click.stop
														/>
													</b-col>
													<b-col v-show="i === 0" cols="auto" class="p-2">
														<b-icon
															icon="trash"
															font-scale="2"
															class="cursor-pointer"
															@click.stop
															@click="deleteTrainingPage(i2)"
														/>
													</b-col>
												</div>
											</b-card-header>
											<b-collapse :id="'accordion-' + i + i2" visible accordion="my-accordion" role="tabpanel">
												<div class="card-body">
													<p class="card-text">
														<b-form-group class="col-md-12">
															<span>Page Image </span> <span class="text-danger"> * </span>
															&nbsp;
															<span class="image-label">(All images in all languages will be changed)</span>
															<br>
															<b-form-file
																ref="imageDetail"
																:placeholder="training[i].detail[i2].TrainingDetailMainImage || `Choose a file ..`"
																size="sm"
																:disabled="i !== 0"
																@click.native="resetImageUploader('training_detail_image',i2)"
																@change="uploadImage($event, 'training_detail_image', i2)"
															/>
															<br>
															<span v-if="training[i].detail[i2].TrainingDetailMainImage"><a :href="training[i].detail[i2].TrainingDetailMainImage" target="_blank">Uploaded Image <b-icon icon="file-image" /> <b-icon icon="check-circle-fill" /></a>
															</span>
															<span v-else class="image-label">ext: .jpg, .png (Rasio 3:4, Max: 1024Kb)</span>
														</b-form-group>
														<b-form-group class="col-md-12">
															Page Title
															<span v-if="regexPagetitle(v2.lang[0].TrainingDetailLanguageTitle)> 60" class="image-label-red">(Maximum characters {{ regexValuePagetitle(v2.lang[0].TrainingDetailLanguageTitle) }}/60)</span>
															<span v-else class="image-label">(Maximum characters {{ regexValuePagetitle(v2.lang[0].TrainingDetailLanguageTitle) }}/60)</span>
															<ckeditor
																v-model="training[i].detail[i2].lang[0].TrainingDetailLanguageTitle"
																:editor="editor"
																:config="editorConfig"
																:name="`description-` + i + i2"
																data-vv-as="description"
																:state="validateState('description-' + i + i2)"
																class="form-control"
															/>
															<!--															<small class="text-danger">{{ veeErrors.first("description-" + i + i2) }}</small>-->
														</b-form-group>
													</p>
													<div v-for="(v3, i3) in training[i].detail[i2].container" :key="i3" class="p-3 mx-3 bg-gray-100 my-4">
														<div class="row justify-content-between pr-3 pl-2 mb-2">
															<b-col cols="6" class="mr-auto px-2">
																<span v-if="(training[i].detail[i2].container[i3].lang[0].TrainingDetailContainerLanguageTitle).length > (training[i].detail[i2].container[i3].TrainingDetailContainerType === 'FULL'?75:57)" class="image-label-red">(Maximum characters {{ (training[i].detail[i2].container[i3].lang[0].TrainingDetailContainerLanguageTitle).length }}/{{ training[i].detail[i2].container[i3].TrainingDetailContainerType === 'FULL'?75:57 }})</span>
																<span v-else class="image-label">(Maximum characters {{ (training[i].detail[i2].container[i3].lang[0].TrainingDetailContainerLanguageTitle).length }}/{{ training[i].detail[i2].container[i3].TrainingDetailContainerType === 'FULL'?75:57 }})</span>
																<b-form-input
																	v-model="training[i].detail[i2].container[i3].lang[0].TrainingDetailContainerLanguageTitle"
																	v-validate="'required'"
																	:name="`tooltip-title-` + i + i2 + i3"
																	data-vv-as="tolltip title"
																	:state="validateState('tooltip-title-' + i + i2 + i3)"
																	size="md"
																	placeholder="Tooltip Title"
																	:maxlength="training[i].detail[i2].container[i3].TrainingDetailContainerType === 'FULL'?75:57"
																/>
																<b-form-invalid-feedback>
																	{{ veeErrors.first("tooltip-title-" + i + i2 + i3) }}
																</b-form-invalid-feedback>
															</b-col>
															<b-col v-show="i === 0" cols="auto" class="p-1">
																<b-icon icon="trash" font-scale="2" class="cursor-pointer" @click="deleteTooltip(i2, i3)" />
															</b-col>
														</div>
														<b-form>
															<b-row>
																<b-form-group class="col-md-6">
																	Container Number / Alphabeth
																	<b-form-input
																		v-model="training[i].detail[i2].container[i3].TrainingDetailContainerNo"
																		size="sm"
																		:name="`container-number-` + i + i2 + i3"
																		:disabled="i !== 0"
																		maxlength="1"
																	/>
																	<b-form-invalid-feedback>
																		{{ veeErrors.first("container-number-" + i + i2 + i3) }}
																	</b-form-invalid-feedback>
																</b-form-group>
																<b-form-group class="col-md-6">
																	<span>Container Icon</span> <br>
																	<span class="image-label">(All images in all languages will be changed)</span>
																	<b-form-file
																		ref="imageUploader"
																		:placeholder="
																			training[i].detail[i2].container[i3].TrainingDetailContainerImage ||
																				`Choose a file ..`
																		"
																		size="sm"
																		:disabled="i !== 0"
																		@click.native="resetImageUploader('container_image',i3)"
																		@change="uploadImage($event, 'container_image', i2, i3)"
																	/>
																	<!-- <input
																		ref="imageUploader"
																		type="file"
																		:placeholder="training[i].detail[i2].TrainingDetailMainImage || `Choose a file ..`"
																		:disabled="i !== 0"
																		@click="resetImageUploader('container_image',i3)"
																		@change="uploadImage($event, 'container_image', i2, i3)"
																	> -->
																	<span v-if="training[i].detail[i2].container[i3].TrainingDetailContainerImage"><a
																		:href="training[i].detail[i2].container[i3].TrainingDetailContainerImage"
																		target="_blank"
																	>Uploaded Image <b-icon icon="file-image" /> <b-icon icon="check-circle-fill" /></a>
																	</span>
																	<span v-else class="image-label">ext: .jpg, .png (Max: 300Kb)</span>
																</b-form-group>
																<b-form-group class="col-md-6">
																	Container Size <span class="text-danger"> * </span>
																	<b-form-select
																		v-model="training[i].detail[i2].container[i3].TrainingDetailContainerType"
																		v-validate="'required'"
																		:options="containerSizes"
																		size="sm"
																		:name="`container-size-` + i + i2 + i3"
																		data-vv-as="container size"
																		:state="validateState('container-size-' + i + i2 + i3)"
																		:disabled="i !== 0"
																		@change="changeContainer($event)"
																	/>
																	<b-form-invalid-feedback>
																		{{ veeErrors.first("container-size-" + i + i2 + i3) }}
																	</b-form-invalid-feedback>
																</b-form-group>
																<b-form-group class="col-md-6">
																	Container Position <span class="text-danger">*</span>
																	<b-form-select
																		v-model="training[i].detail[i2].container[i3].TrainingDetailContainerPosition"
																		v-validate="'required'"
																		:options="containerPositions"
																		size="sm"
																		:name="`container-position-` + i + i2 + i3"
																		data-vv-as="container position"
																		:state="validateState('container-position-' + i + i2 + i3)"
																		:disabled="i !== 0"
																	/>
																	<b-form-invalid-feedback>
																		{{ veeErrors.first("container-position-" + i + i2 + i3) }}
																	</b-form-invalid-feedback>
																</b-form-group>
																<b-form-group class="col-md-12">
																	Container Description <span class="text-danger"> * </span>
																	&nbsp;
																	<span v-if="(training[i].detail[i2].container[i3].lang[0].TrainingDetailContainerLanguageDesc).length > (training[i].detail[i2].container[i3].TrainingDetailContainerType === 'FULL'?220:160)" class="image-label-red">(Maximum characters {{ (training[i].detail[i2].container[i3].lang[0].TrainingDetailContainerLanguageDesc).length }}/{{ training[i].detail[i2].container[i3].TrainingDetailContainerType === 'FULL'?220:160 }})</span>
																	<span v-else class="image-label">(Maximum characters {{ (training[i].detail[i2].container[i3].lang[0].TrainingDetailContainerLanguageDesc).length }}/{{ training[i].detail[i2].container[i3].TrainingDetailContainerType === 'FULL'?220:160 }})</span>
																	<b-form-textarea
																		id="textarea"
																		v-model="
																			training[i].detail[i2].container[i3].lang[0].TrainingDetailContainerLanguageDesc
																		"
																		v-validate="'required'"
																		placeholder="Enter something..."
																		rows="3"
																		max-rows="6"
																		:name="`container-desc-` + i + i2 + i3"
																		data-vv-as="container description"
																		:state="validateState('container-desc-' + i + i2 + i3)"
																		:maxlength="training[i].detail[i2].container[i3].TrainingDetailContainerType === 'FULL'?220:160"
																	/>
																	<b-form-invalid-feedback>
																		{{ veeErrors.first("container-desc-" + i + i2 + i3) }}
																	</b-form-invalid-feedback>
																</b-form-group>
															</b-row>
														</b-form>
													</div>

													<b-row class="ml-3">
														<b-col
															v-show="v.TrainingListLanguageCode === 'in'"
															md="3"
															class="border text-center cursor-pointer btn-add p-3 my-2"
															style="background-color: #f3f4f6f1"
															@click="addTooltip(i2, i)"
														>
															<span class="p-3">+ Add Tooltip</span>
														</b-col>
													</b-row>
												</div>
											</b-collapse>
										</b-card>
									</div>
								</div>
								<b-row
									v-show="v.TrainingListLanguageCode === 'in'"
									class="p-2 my-4 mx-1 border justify-content-center cursor-pointer btn-add"
									@click="addTrainingPage(i, v)"
								>
									<span class="p-3">+ Add Training Page</span>
								</b-row>
							</b-tab>
							<!-- New Tab Button (Using tabs-end slot) -->
							<template #tabs-end>
								<b-nav-item role="presentation" href="#" @click.prevent="openFormLanguageList">
									<b class="text-success">+ Language</b>
								</b-nav-item>
							</template>
							<!-- Render this if no tabs -->
							<template #empty>
								<div class="text-center text-muted">
									There are no open tabs<br>
									Open a new tab using the <b>+ Language</b> button above.
								</div>
							</template>
						</b-tabs>
					</b-card>
				</div>
				<div class="w-100 mt-3">
					<b-button variant="primary" size="sm" class="float-right" :disabled="trainingId ? false : true" @click="saveDetailTraining('PUBLISHED')">
						Publish
					</b-button>
					<b-button
						variant="outline-primary"
						size="sm"
						class="float-right mr-1"
						:disabled="trainingId ? false : true"
						@click="saveDetailTraining('DRAFT')"
					>
						Save Draft
					</b-button>
					<!-- <b-button variant="light" size="sm" class="float-right mr-1"> Cancel </b-button> -->
				</div>
			</b-col>
			<b-col lg="5" sm="12" md="12" xs="12" class="d-flex justify-content-center boxcanvas">
				<div class="canvas row" :style="selected == '1' ? 'position:fixed' : 'position:absolute'">
					<div class="col">
						<div class="titlecanvas">{{ canvasTitle }}</div>
					</div>
					<div class="col">
						<canvas id="myCanvas" ref="myCanvas" width="360" height="640" :style="styleCanvas" class="bgcanvas" />
					</div>
				</div>
				<template v-if="showRadioButton == true">
					<b-form-radio-group

						v-model="selected"

						:style="selected == '1' ? 'position:fixed; margin-top: -20px;margin-left: 50px' : 'position:absolute;margin-top: -20px;margin-left: 50px'"

						:options="options"

						:aria-describedby="ariaDescribedby"

						name="radio-inline"
					>
					</b-form-radio-group>
				</template>
			</b-col>
		</b-row>
		<ktv-modal v-model="formCommodity" size="lg" :title="commodityTitle" no-close-on-backdrop no-close-on-esc @close="closeFormCommodity">
			<template #content>
				<b-col>
					<div class="form-group row">
						<label class="col-sm-3">Commodity<span class="text-danger"> *</span></label>
						<div class="col-sm-9">
							<b-form-select
								v-model="commodityFields.id"
								v-validate="'required'"
								:options="commodities"
								name="commodity-1"
								data-vv-scope="commodity"
								data-vv-as="commodity"
								:state="validateState('commodity-1')"
								@change="getPartnerAccess($event)"
							/>
							<span class="text-danger">
								{{ veeErrors.first("commodity.commodity-1") }}
							</span>
						</div>
					</div>
					<div class="form-group row mt-3">
						<label class="col-sm-3">Status Type<span class="text-danger"> *</span></label>
						<div class="col-sm-9">
							<b-form-radio-group v-model="commodityFields.statusType" :options="statusTypes" name="radio-inline" />
						</div>
					</div>
					<div v-if="commodityFields.statusType === 'PRIVATE'" class="form-group row">
						<label class="col-sm-3">Partner Access<span class="text-danger"> *</span></label>
						<div v-if="loadingPartner" class="col-sm-9">
							<b-skeleton-table :rows="5" :columns="2" :table-props="{ bordered: true, striped: true }" />
						</div>
						<div v-else-if="!loadingPartner && partnerAccessList.length" class="col-sm-9">
							<v-multiselect-listbox
								v-model="commodityFields.partnerAccess"
								v-validate="'required'"
								style="width: 100%"
								:style="{
									'pointer-events: none': disabledForm,
								}"
								:options="partnerAccessList"
								:reduce-display-property="(option) => option.text"
								:reduce-value-property="(option) => option.value"
								no-options-found-text="No data found"
								no-selected-options-found-text="No selected data found"
								name="partner"
								data-vv-scope="commodity"
								data-vv-as="partner access"
								:state="validateState('partner')"
							/>
							<span class="text-danger">
								{{ veeErrors.first("commodity.partner") }}
							</span>
						</div>
						<div v-else class="col-sm-9"><span class="text-warning">No Partner Data</span></div>
					</div>
				</b-col>
			</template>
			<template #footer>
				<b-button variant="primary" size="sm" class="float-right" @click="saveCommodity(commodityFields)"> Save </b-button>
				<b-button variant="light" size="sm" class="float-right" style="margin-right: 5px" @click="closeFormCommodity"> Cancel </b-button>
			</template>
		</ktv-modal>

		<ktv-modal v-model="formLanguageList" size="sm" :title="formLanguageListTitle" no-close-on-backdrop no-close-on-esc @close="closeFormLanguageList">
			<template #content>
				<b-form-group>
					<b-form-select v-model="languageSelected" size="lg" :options="filteredLanguages" :select-size="10" />
				</b-form-group>
			</template>
			<template #footer>
				<b-button variant="primary" size="sm" class="float-right" :disabled="!languageSelected" @click="addLanguage(languageSelected)"> Add </b-button>
			</template>
		</ktv-modal>
	</div>
</template>

<script>
	import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
	import { KtvBreadcrumb, KtvModal, KtvTable, KtvButton } from "@/components"
	import { mapGetters } from "vuex"

	export default {
		components: {
			KtvBreadcrumb,
			KtvModal,
			KtvTable,
			KtvButton,
		},
		data() {
			return {
				commodities: [],
				categories: [],
				subCategories: [],
				statusTypes: [
					{
						value: "PUBLIC",
						text: "PUBLIC",
					},
					{
						value: "PRIVATE",
						text: "PRIVATE",
					},
				],
				partnerAccessList: [],
				roleAccessList: [
					{
						value: 1,
						text: "Staff",
					},
				],
				containerSizes: [
					{
						value: "FIXED",
						text: "FIXED",
					},
					{
						value: "FULL",
						text: "FULL",
					},
				],
				containerPositions: [
					{
						value: "TOP_RIGHT",
						text: "TOP_RIGHT",
					},
					{
						value: "TOP_LEFT",
						text: "TOP_LEFT",
					},
					{
						value: "TOP_MIDDLE",
						text: "TOP_MIDDLE",
					},
					{
						value: "MIDDLE_RIGHT",
						text: "MIDDLE_RIGHT",
					},
					{
						value: "MIDDLE_LEFT",
						text: "MIDDLE_LEFT",
					},
					{
						value: "CENTER",
						text: "CENTER",
					},
					{
						value: "BOTTOM_RIGHT",
						text: "BOTTOM_RIGHT",
					},
					{
						value: "BOTTOM_LEFT",
						text: "BOTTOM_LEFT",
					},
					{
						value: "BOTTOM_MIDDLE",
						text: "BOTTOM_MIDDLE",
					},
				],
				editor: ClassicEditor,
				editorConfig: {
					// The configuration of the editor.
					toolbar: [ 'heading', '|', 'bold', 'italic', 'link', '|', 'bulletedList', 'numberedList', '|', 'blockQuote', 'undo', 'redo' ],
				},
				formCommodity: false,
				disabledForm: false,
				/* eslint-disable global-require */
				iconedit: require("@/assets/images/icon/iconedit.png"),
				icondelete: require("@/assets/images/icon/icondelete.png"),
				iconview: require("@/assets/images/icon/iconview.png"),
				icondropdown: require("@/assets/images/icon/icondropdown.png"),
				commodityTable: {
					columns: [
						{
							label: "Action",
							field: "button",
							html: true,
							tdClass: "text-center",
							thClass: "text-center",
						},
						{
							label: "Commodity",
							field: "id",
							thClass: "text-left",
						},
						{
							label: "Status",
							field: "statusType",
							thClass: "text-left",
						},
						{
							label: "Partner Access",
							field: "partnerAccess",
							thClass: "text-left",
						},
					],
					rows: [],
				},
				tableLoad: false,
				formLanguageList: false,
				languageSelected: null,
				languages: [],
				commodityFields: {
					id: "",
					statusType: "PUBLIC",
					partnerAccess: [],
				},
				trainingHeaders: {
					category: null,
					subCategory: null,
					// roleAccess: null,
					// author: null
				},
				training: [
					{
						TrainingListLanguageCode: "in",
						TrainingListLanguageTitle: "",
						TrainingListLanguageSubTitle: "",
						TrainingListLanguageDescription: "",
						detail: [],
					},
				],
				trainingImage: null,
				commodityOriginalIndex: null,
				loadingHeader: true,
				loadingTraining: true,
				filteredLanguages: [],
				canvasDetailImage: null,
				trainingDetailId: "",
				trainingDetailPageNo: null,
				loadingPartner: false,
				loadingCommodityTable: false,
				showRadioButton : false,

				selected: '1',

				options: [

					{ text: 'Image Fix', value: '1' },

					{ text: 'Image Scroll', value: '2' },

				],
			}
		},
		computed: {
			commodityTitle() {
				return "Add Commodity"
			},
			formLanguageListTitle() {
				return "Add Language"
			},
			...mapGetters({
				loggedInUser: "AUTHDATA/loggedInUser",
			}),
			canAddCommodity() {
				return Object.values(this.trainingHeaders).every((o) => o !== null)
			},
			canSaveTrainingHeader() {
				return !!this.commodityTable.rows.length
			},
			trainingId: {
				get() {
					return this.$route?.query?.id
				},
				set(newName) {
					return newName
				},
			},
			styleCanvas: {
				get() {
					return {
						backgroundImage: `url(${this.canvasDetailImage})`
					}
				},
			},
			canvasTitle() {
				if (this.trainingDetailPageNo) return `Page ${this.trainingDetailPageNo} Selected`
				return ``
			},
		},
		watch: {
			training: {
				immediate: true,
				handler: function (v) {
					let langs = v.map((v2) => v2.TrainingListLanguageCode)
					let temp = this.languages.filter((val) => !langs.includes(val.value))
					this.filteredLanguages = temp.length ? temp : this.languages
				},
			},
			"commodityFields.statusType": {
				immediate: true,
				handler(v) {
					if (v === "PUBLIC") this.commodityFields.partnerAccess = []
				},
			},
		},
		created() {
			this.getCategory()
			this.getCommodities()
			this.getTrainingHeader()
			this.getLanguages()
			this.getTrainingDetail()
		},
		mounted() {
			this.loadCanvas()
		},
		methods: {
			openimg() {
				document.getElementById("imageDetail").click()
			},
			addTrainingPage() {
				this.training.forEach((v, i) => {
					this.training[i].detail.push({
						TrainingDetailPageNo: "",
						TrainingDetailMainImage: null,
						TrainingDetailId: "",
						lang: [
							{
								TrainingDetailLanguageCode: v.TrainingListLanguageCode,
								TrainingDetailLanguageTitle: "",
							},
						],
						container: [],
					})
				})
			},
			deleteTrainingPage(i2) {
				this.$swal({
					title: "Are you sure?",
					text: "Data will be deleted",
					type: "warning",
					showCancelButton: true,
					confirmButtonColor: "#3085d6",
					cancelButtonColor: "#d33",
					confirmButtonText: "Yes",
				}).then((r) => {
					if (r.value) {
						this.training.forEach((val, index) => {
							this.training[index].detail.splice(i2, 1)
						})
					}
				})
			},
			getCategory() {
				this.categories = []
				this.$http
					.Get({}, this.$urlApi.training.category.get)
					.then((r) => {
						if (r.success) {
							r.results.forEach((e) => {
								this.categories.push({
									value: e.CategoryId,
									text: e.CategoryLanguageText,
								})
							})
						} else {
							this.$swal("Error!", r.error_massage, "error")
						}
					})
					.catch((e) => {
						this.$swal("Error!", e.response.data.error_message, "error")
					})
			},
			getSubCategory(categoryId) {
				let payload = {
					categoryId: categoryId,
				}
				this.subCategories = []
				this.$http
					.Get(payload, this.$urlApi.training.subCategory.get)
					.then((r) => {
						if (r.success) {
							r.results.forEach((i) => {
								this.subCategories.push({
									value: i.id,
									text: i.value,
								})
							})
						} else {
							this.$swal("Error!", r.error_massage, "error")
						}
					})
					.catch((e) => {
						this.$swal("Error!", e.response.data.error_message, "error")
					})
			},
			closeFormCommodity() {
				this.formCommodity = false
				this.commodityOriginalIndex = null
				this.commodityFields = {
					id: "",
					statusType: "PUBLIC",
					partnerAccess: [],
				}
			},
			openFormCommodity(val = null) {
				if (val !== null) {
					if (val.statusType === "PRIVATE") this.getPartnerAccess(val.id)
					this.commodityOriginalIndex = val.originalIndex
					this.commodityFields = {
						id: val.id,
						statusType: val.statusType,
						partnerAccess: val.partnerAccess.map((v) => v.TrainingCommodityPartnerCode),
					}
				}
				this.formCommodity = true
			},
			deleteLanguageTab(i) {
				this.$swal({
					title: "Are you sure?",
					text: "Data will be deleted",
					type: "warning",
					showCancelButton: true,
					confirmButtonColor: "#3085d6",
					cancelButtonColor: "#d33",
					confirmButtonText: "Yes",
				}).then((r) => {
					if (r.value) {
						this.training.splice(i, 1)
					}
				})
			},
			openFormLanguageList() {
				// eslint-disable-next-line consistent-return
				this.$validator.validateAll().then((result) => {
					if (!result) {
						this.makeToast("Warning", "danger", "All Mandatory(*) Field Must Be Filled")
						return false
					}
					this.formLanguageList = true
				})
			},
			closeFormLanguageList() {
				this.formLanguageList = false
			},
			backToList() {
				this.$router
					.push({
						path: "/training/list",
					})
					.catch(() => true)
			},
			getCommodities() {
				this.commodities = []
				this.$http
					.Get({}, this.$urlApi.training.commodity)
					.then((r) => {
						if (r.success) {
							r.results.forEach((e) => {
								this.commodities.push({
									value: e.EntityListOfValueID,
									text: e.Value,
								})
							})
						} else {
							this.$swal("Error!", r.error_massage, "error")
						}
					})
					.catch((e) => {
						this.$swal("Error!", e.response.data.error_message, "error")
					})
			},
			saveCommodity(data) {
				this.$validator.validateAll("commodity").then((result) => {
					if (!result) {
						this.makeToast("Warning", "danger", "All Mandatory(*) Field Must Be Filled")
						return false
					}
					if (this.commodityOriginalIndex !== null) {
						this.commodityTable.rows.splice(this.commodityOriginalIndex, 1)
					}
					let newPartnerAccess = data.partnerAccess.map((v) => ({ TrainingCommodityPartnerCode: v }))
					data.partnerAccess = newPartnerAccess
					this.commodityTable.rows.push(data)

					this.$swal("Added!", "Your data has been added.", "success")
					this.closeFormCommodity()
					this.saveTrainingHeader()

					return true
				})
			},
			createTrainingHeader(payload) {
				this.$http
					.Post(payload, this.$urlApi.training.header.create)
					.then((r) => {
						if (r.success) {
							this.$swal.closeModal()
							this.trainingId = r.results.training_id
							this.$router.push({ query: { id: r.results.training_id } })
							this.getTrainingHeader()
							this.$swal("Saved!", "Your data has been added.", "success")
						}
					})
					.catch((e) => {
						this.$swal.closeModal()
						this.$swal("Failed!", e.response.data.error_message, "error")
					})
			},
			updateTrainingHeader(payload, id) {
				payload.TrainingId = id
				this.$http
					.Put(payload, this.$urlApi.training.header.update)
					.then((r) => {
						if (r.success) {
							this.$swal.closeModal()
							this.getTrainingHeader()
							this.$swal("Saved!", "Your data has been update.", "success")
						}
					})
					.catch((e) => {
						this.$swal.closeModal()
						this.$swal("Failed!", e.response.data.error_message, "error")
					})
			},
			saveTrainingHeader() {
				let newCommodities = []
				this.commodityTable.rows.forEach((v) => {
					newCommodities.push({
						CommodityCode: v.id,
						CommodityStatus: v.statusType,
						CommodityPartner: v.partnerAccess.map((v2) => v2.TrainingCommodityPartnerCode),
					})
				})
				let payload = {
					CategoryId: this.trainingHeaders.category,
					SubCategoryId: this.trainingHeaders.subCategory,
					Commodity: newCommodities,
				}
				this.showLoadingMessage("Saving Data...")
				if (this.trainingId) {
					this.updateTrainingHeader(payload, this.trainingId)
				} else {
					this.createTrainingHeader(payload)
				}
			},
			showLoadingMessage(message) {
				this.$swal.fire({
					title: `${message}`,
					animation: true,
					allowOutsideClick: false,
					onBeforeOpen: () => {
						this.$swal.showLoading()
					},
				})
			},
			getTrainingHeader() {
				if (this.trainingId) {
					this.loadingCommodityTable = true
					this.$http
						.Get({ TrainingId: this.trainingId }, this.$urlApi.training.header.get)
						.then((r) => {
							if (r.success) {
								let header = r.results.training_id.header
								let commodity = r.results.training_id.commodity
								this.trainingImage = header.TrainingImage
								this.trainingHeaders = {
									category: header.TrainingCategoryId,
									subCategory: header.TrainingSubCategoryId,
									// roleAccess: header.TrainingRoleAccess,
								}
								if (header.TrainingCategoryId) {
									this.getSubCategory(header.TrainingCategoryId)
								}
								this.commodityTable.rows = []
								commodity.forEach((v) => {
									this.commodityTable.rows.push({
										id: v.TrainingCommodityCode,
										statusType: v.TrainingCommodityStatus,
										partnerAccess: v.partner,
									})
								})
								this.loadingCommodityTable = false
								this.loadingHeader = false
							} else {
								this.$swal("Error!", r.error_massage, "error")
							}
						})
						.catch((e) => {
							this.$swal("Error!", e.response.data.error_message, "error")
						})
				} else {
					this.loadingHeader = false
				}
			},
			getLanguages() {
				this.$http
					.Get({}, this.$urlApi.training.language)
					.then((r) => {
						this.$swal.closeModal()
						if (r.success) {
							let langs = r.results.sort((a, b) => parseFloat(a.id) - parseFloat(b.id))
							langs.forEach((v) => {
								this.languages.push({
									value: v.language_code,
									text: v.language_desc,
								})
							})
						} else {
							this.$swal("Error!", r.error_massage, "error")
						}
					})
					.catch((e) => {
						this.$swal("Error!", e.response.data.message, "error")
					})
			},
			addLanguage(lang) {
				let data = this.languages.find((v) => v.value === lang)
				let detail = []
				if (this.training.length) {
					this.training[0].detail.forEach((v) => {
						detail.push({
							TrainingDetailId: "",
							TrainingDetailMainImage: v.TrainingDetailMainImage,
							TrainingDetailPageNo: v.TrainingDetailPageNo,
							lang: [
								{
									TrainingDetailLanguageCode: data.value,
									TrainingDetailLanguageId: "",
									TrainingDetailLanguageTitle: "",
								},
							],
							container: v.container.map((v2) => ({
								TrainingDetailContainerImage: v2.TrainingDetailContainerImage,
								TrainingDetailContainerPosition: v2.TrainingDetailContainerPosition,
								TrainingDetailContainerType: v2.TrainingDetailContainerType,
								TrainingDetailContainerNo: v2.TrainingDetailContainerNo,
								lang: v2.lang.map(() => ({
									TrainingDetailContainerLanguageCode: data.value,
									TrainingDetailContainerLanguageDesc: "",
									TrainingDetailContainerLanguageTitle: "",
								})),
							})),
						})
					})
				}
				this.training.push({
					TrainingListLanguageCode: data.value,
					TrainingListLanguageTitle: "",
					TrainingListLanguageSubTitle: "",
					TrainingListLanguageDescription: "",
					detail: detail,
				})
				this.$swal("Added!", "Your data has been added.", "success")
				this.closeFormLanguageList()
			},
			saveDetailTraining(submitType) {
				this.$validator.validateAll().then((result) => {
					if (!result) {
						this.makeToast("Warning", "danger", "All Mandatory(*) Field Must Be Filled")
						return false
					}
					let TrainingLanguage = this.training.map((v) => ({
						LanguageCode: v.TrainingListLanguageCode,
						Title: v.TrainingListLanguageTitle,
						SubTitle: v.TrainingListLanguageSubTitle,
						Description: v.TrainingListLanguageDescription,
						SubmitType: submitType,
					}))
					let TrainingDetail = this.training[0].detail.map((v, i) => ({
						PageNo: this.training[0]?.detail[i]?.TrainingDetailPageNo,
						Image: this.training[0]?.detail[i]?.TrainingDetailMainImage,
						TrainingDetailLanguage: this.training.map((v2) => ({
							LanguageCode: v2.detail[i]?.lang[0]?.TrainingDetailLanguageCode,
							DetailTitle: v2.detail[i]?.lang[0]?.TrainingDetailLanguageTitle,
						})),
						Container: this.training[0]?.detail[i]?.container.map((v2, i2) => ({
							ContainerType: v2.TrainingDetailContainerType,
							ContainerPosition: v2.TrainingDetailContainerPosition,
							ContainerImage: v2.TrainingDetailContainerImage,
							ContainerNo: v2.TrainingDetailContainerNo,
							ContainerLanguage: this.training.map((v3) => ({
								LanguageCode: v3.detail[i]?.container[i2]?.lang[0]?.TrainingDetailContainerLanguageCode,
								ContainerTitle: v3.detail[i]?.container[i2]?.lang[0]?.TrainingDetailContainerLanguageTitle,
								ContainerDesc: v3.detail[i]?.container[i2]?.lang[0]?.TrainingDetailContainerLanguageDesc,
							})),
						})),
					}))
					let payload = {
						TrainingId: this.trainingId,
						TrainingImage: this.trainingImage,
						TrainingLanguage: TrainingLanguage,
						TrainingDetail: TrainingDetail,
					}
					this.showLoadingMessage("Saving Data...")
					this.$http
						.Post(payload, this.$urlApi.training.detail.create)
						.then((r) => {
							if (r.success) {
								this.$swal.closeModal()
								this.getTrainingDetail()
								this.$swal("Saved!", "Your data has been added.", "success")
							}
						})
						.catch((e) => {
							this.$swal.closeModal()
							this.$swal("Failed!", e.response.data.error_message, "error")
						})
					return true
				})
			},
			uploadImage(event, objectType, index, index2) {
				let data = new FormData()
				data.append("file_data", event.target.files[0])
				data.append("object_type", objectType)
				this.$http
					.Post(data, this.$urlApi.training.uploadImage)
					.then((r) => {
						if (r.success) {
							switch (objectType) {
							case "training_image":
								this.trainingImage = r.results.s3_url
								break
							case "training_detail_image":
								this.training[0].detail[index].TrainingDetailMainImage = r.results.s3_url
								break
							case "container_image":
								this.training[0].detail[index].container[index2].TrainingDetailContainerImage = r.results.s3_url
								break
							default:
								break
							}
						}
					})
					.catch((err) => {
						this.$swal("Error!", err?.response?.data?.error_message, "error")
					})
			},
			resetImageUploader(typ, tynum) {
				if (typ === "container_image") {
					this.$refs.imageUploader[tynum].reset();
				}
				else if (typ === "training_detail_image") {
					this.$refs.imageDetail[tynum].reset();
				}
				else if (typ === "training_image") {
					this.$refs.imageMain[0].reset();
				}
			},
			addTooltip(i2, j) {
				if ((this.training[j].detail[i2].container).length === 2 || (this.training[j].detail[i2].container).length > 2) {
					this.$swal("", "Tooltip Maksimal 2" , "info")
				} else {
					this.training.forEach((v, i) => {
						this.training[i].detail[i2].container.push({
							TrainingDetailContainerType: "",
							TrainingDetailContainerPosition: "",
							TrainingDetailContainerImage: "",
							TrainingDetailContainerNo: "",
							lang: [
								{
									TrainingDetailContainerLanguageCode: v.TrainingListLanguageCode,
									TrainingDetailContainerLanguageTitle: "",
									TrainingDetailContainerLanguageDesc: "",
								},
							],
						})
					})
				}
			},
			deleteCommodity(item) {
				this.commodityTable.rows.splice(item.originalIndex, 1)
				this.saveTrainingHeader()
			},
			deleteTooltip(i2, i3) {
				this.$swal({
					title: "Are you sure?",
					text: "Data will be deleted",
					type: "warning",
					showCancelButton: true,
					confirmButtonColor: "#3085d6",
					cancelButtonColor: "#d33",
					confirmButtonText: "Yes",
				}).then((r) => {
					if (r.value) {
						this.training.forEach((val, index) => {
							this.training[index].detail[i2].container.splice(i3, 1)
						})
					}
				})
			},
			getTrainingDetail() {
				if (this.trainingId)
					this.$http
						.Get({ trainingId: this.trainingId }, this.$urlApi.training.detail.get)
						.then((r) => {
							if (r.success) {
								if (r.results.length) {
									this.training = []
									r.results.forEach((v, i) => {
										this.training.push({
											TrainingListLanguageCode: v.TrainingListLanguageCode,
											TrainingListLanguageTitle: v.TrainingListLanguageTitle,
											TrainingListLanguageSubTitle: v.TrainingListLanguageSubTitle,
											TrainingListLanguageDescription: v.TrainingListLanguageDescription,
											detail: [],
										})
										v.detail.forEach((v2, i2) => {
											this.training[i].detail.push({
												TrainingDetailPageNo: v2.TrainingDetailPageNo,
												TrainingDetailMainImage: v2.TrainingDetailMainImage,
												TrainingDetailId: v2.TrainingDetailId,
												lang: v2.lang.length
													? v2.lang
													: [
														{
															TrainingDetailLanguageId: "",
															TrainingDetailLanguageCode: v.TrainingListLanguageCode,
															TrainingDetailLanguageTitle: "",
														},
													],
												container: [],
											})
											v2.container.forEach((v3) => {
												this.training[i].detail[i2].container.push({
													TrainingDetailContainerType: v3.TrainingDetailContainerType,
													TrainingDetailContainerPosition: v3.TrainingDetailContainerPosition,
													TrainingDetailContainerImage: v3.TrainingDetailContainerImage,
													TrainingDetailContainerNo: v3.TrainingDetailContainerNo,
													lang: v3.lang.length
														? v3.lang
														: [
															{
																TrainingDetailContainerLanguageCode: v.TrainingListLanguageCode,
																TrainingDetailContainerLanguageTitle: "",
																TrainingDetailContainerLanguageDesc: "",
															},
														],
												})
											})
										})
									})
								}

								this.loadingTraining = false
							} else {
								this.$swal("Error!", r.error_massage, "error")
							}
						})
						.catch((e) => {
							this.$swal("Error!", e.response.data.error_message, "error")
						})
				else this.loadingTraining = false
			},
			loadImages(sources, callback) {
				let images = {}
				let loadedImages = 0
				let numImages = 0
				Object.keys(sources).forEach(function () {
					numImages++
				})
				Object.keys(sources).forEach(function (src) {
					images[src] = new Image()
					images[src].onload = function () {
						if (++loadedImages <= numImages) {
							callback(images)
						}
					}
					images[src].src = sources[src]
				})
			},
			loadCanvas(val = null, index1 = "", index2 = "") {
				this.trainingDetailId = "" + index1 + index2
				this.canvasDetailImage = val?.TrainingDetailMainImage
				this.trainingDetailPageNo = val?.TrainingDetailPageNo
				let containers = val?.container || []
				let context = this.$refs.myCanvas.getContext("2d")
				context.clearRect(0, 0, 360, 640)
				let newContainers = containers.reduce((a, v) => ({ ...a, [v.TrainingDetailContainerPosition]: v.TrainingDetailContainerImage }), {})

				let maxWidth = 305
				let lineHeight = 15
				let regex = /(<([^>]+)>)/ig;
				let body = val.lang[0].TrainingDetailLanguageTitle;
				let result = body.replace(regex, "");
				let regex1 = /&nbsp;/g;
				let body1 = result;
				let result1 = body1.replace(regex1, "");
				context.fillStyle = "#fff"
				context.font = "16px calibri"
				context.textAlign = "center"
				// context.fillText(result, 175, 30, 290, 15)
				this.wrapText(context, result1, 175, 60, maxWidth, lineHeight)
				containers.forEach((v) => {
					let rectXFull = null
					let rectWidthFull = null
					let titleXFull = null
					let descXFull = null
					let imageXFull = null
					let iconXFull = null

					if (v.TrainingDetailContainerType === "FULL") {
						rectXFull = 20
						rectWidthFull = 320
						titleXFull = 30
						descXFull = 30
						imageXFull = 290
						iconXFull = 305
					}
					switch (v.TrainingDetailContainerPosition) {
					case "TOP_RIGHT": {
						let sizeObj = {
							rectX: rectXFull || 70,
							rectY: 90,
							rectWidth: rectWidthFull || 270,
							rectHeight: 150,
							titleX: titleXFull || 80,
							titleY: 125,
							descX: descXFull || 80,
							descY: 160,
							imageX: imageXFull || 290,
							imageY: 105,
							iconX: iconXFull || 305,
							iconY: 104,
						}
						this.drawCanvas(context, v, newContainers, sizeObj)
						break
					}
					case "TOP_LEFT": {
						let sizeObj = {
							rectX: rectXFull || 20,
							rectY: 90,
							rectWidth: rectWidthFull || 270,
							rectHeight: 150,
							titleX: titleXFull || 30,
							titleY: 125,
							descX: descXFull || 30,
							descY: 160,
							imageX: imageXFull || 240,
							imageY: 105,
							iconX: iconXFull || 255,
							iconY: 104,
						}
						this.drawCanvas(context, v, newContainers, sizeObj)
						break
					}
					case "TOP_MIDDLE": {
						let sizeObj = {
							rectX: rectXFull || 45,
							rectY: 90,
							rectWidth: rectWidthFull || 270,
							rectHeight: 150,
							titleX: titleXFull || 55,
							titleY: 125,
							descX: descXFull || 55,
							descY: 160,
							imageX: imageXFull || 264,
							imageY: 105,
							iconX: iconXFull || 280,
							iconY: 104,
						}
						this.drawCanvas(context, v, newContainers, sizeObj)
						break
					}
					case "MIDDLE_RIGHT": {
						let sizeObj = {
							rectX: rectXFull || 70,
							rectY: 270,
							rectWidth: rectWidthFull || 270,
							rectHeight: 150,
							titleX: titleXFull || 80,
							titleY: 305,
							descX: descXFull || 80,
							descY: 340,
							imageX: imageXFull || 290,
							imageY: 285,
							iconX: iconXFull || 305,
							iconY: 284,
						}
						this.drawCanvas(context, v, newContainers, sizeObj)
						break
					}
					case "MIDDLE_LEFT": {
						let sizeObj = {
							rectX: rectXFull || 20,
							rectY: 270,
							rectWidth: rectWidthFull || 270,
							rectHeight: 150,
							titleX: titleXFull || 30,
							titleY: 305,
							descX: descXFull || 30,
							descY: 340,
							imageX: imageXFull || 240,
							imageY: 285,
							iconX: iconXFull || 255,
							iconY: 284,
						}
						this.drawCanvas(context, v, newContainers, sizeObj)
						break
					}
					case "CENTER": {
						let sizeObj = {
							rectX: rectXFull || 45,
							rectY: 270,
							rectWidth: rectWidthFull || 270,
							rectHeight: 150,
							titleX: titleXFull || 55,
							titleY: 305,
							descX: descXFull || 55,
							descY: 340,
							imageX: imageXFull || 264,
							imageY: 285,
							iconX: iconXFull || 280,
							iconY: 284,
						}
						this.drawCanvas(context, v, newContainers, sizeObj)
						break
					}
					case "BOTTOM_RIGHT": {
						let sizeObj = {
							rectX: rectXFull || 70,
							rectY: 450,
							rectWidth: rectWidthFull || 270,
							rectHeight: 150,
							titleX: titleXFull || 80,
							titleY: 485,
							descX: descXFull || 80,
							descY: 520,
							imageX: imageXFull || 290,
							imageY: 465,
							iconX: iconXFull || 305,
							iconY: 464,
						}
						this.drawCanvas(context, v, newContainers, sizeObj)
						break
					}
					case "BOTTOM_LEFT": {
						let sizeObj = {
							rectX: rectXFull || 20,
							rectY: 450,
							rectWidth: rectWidthFull || 270,
							rectHeight: 150,
							titleX: titleXFull || 30,
							titleY: 485,
							descX: descXFull || 30,
							descY: 520,
							imageX: imageXFull || 240,
							imageY: 465,
							iconX: iconXFull || 255,
							iconY: 464,
						}
						this.drawCanvas(context, v, newContainers, sizeObj)
						break
					}
					case "BOTTOM_MIDDLE": {
						let sizeObj = {
							rectX: rectXFull || 45,
							rectY: 450,
							rectWidth: rectWidthFull || 270,
							rectHeight: 150,
							titleX: titleXFull || 55,
							titleY: 485,
							descX: descXFull || 55,
							descY: 520,
							imageX: imageXFull || 264,
							imageY: 465,
							iconX: iconXFull || 280,
							iconY: 464,
						}
						this.drawCanvas(context, v, newContainers, sizeObj)
						break
					}
					default:
						break
					}
				})
			},
			drawCanvas(context, v, newContainers, s) {
				this.showRadioButton = true
				let maxWidth = v.TrainingDetailContainerType === "FULL" ? 305 : 260
				let maxtitleWidth = v.TrainingDetailContainerType === "FULL" ? 260 : 220
				let lineHeight = 15
				context.fillStyle = "#fff"
				context.fillRect(s.rectX, s.rectY, s.rectWidth, s.rectHeight)

				context.textAlign = "left"
				context.fillStyle = "#111111cf"
				context.font = "14px calibri"
				this.wrapText(context, v.lang[0].TrainingDetailContainerLanguageTitle, s.titleX, s.titleY, maxtitleWidth, lineHeight)
				// context.fillText(v.lang[0].TrainingDetailContainerLanguageTitle, s.titleX, s.titleY)

				context.fillStyle = "#111111cf"
				context.font = "12px calibri"
				this.wrapText(context, v.TrainingDetailContainerNo, s.iconX, s.iconY, maxWidth, lineHeight)
				context.fillStyle = "#111111cf"
				context.font = "12px Calibri"
				// sampai sini
				this.wrapLine(context, v.lang[0].TrainingDetailContainerLanguageDesc, s.descX, s.descY, maxWidth, lineHeight)
				this.loadImages(newContainers, function (images) {
					context.drawImage(images[v.TrainingDetailContainerPosition], s.imageX, s.imageY, 40, 40)
				})
			},
			wrapLine(context, text, x, y, maxWidth, lineHeight=200) {
				// console.log('cont',context )
				// console.log('cont text',text )
				// console.log('apa ini', 'content: '+ context, 'text: '+text, 'x: '+x, 'y: '+y, 'max: '+maxWidth, 'line: '+lineHeight)
				/* eslint-disable no-shadow */
				// eslint-disable-next-line no-unused-vars
				let words = text.split(" ")
				let line = ""
				let slash = text.split('\n')
				for (let i = 0; i<slash.length; i++) {
					let testLine = slash[i] + " "
					let metrics = context.measureText(testLine)
					let testWidth = metrics.width
					if (testWidth > maxWidth && i >= 0) {
						let words = text.split(" ")
						let line = ""
						for (let n = 0; n < words.length; n++) {
							let testLine = line + words[n] + " "
							let metrics = context.measureText(testLine)
							let testWidth = metrics.width
							let pattern = /\n/;
							let result = testLine.search(pattern);
							if (testWidth > maxWidth && n > 0) {

								if (result > 0) {
									let slash = line.split("\n")
									context.fillText(slash[0], x, y)
									line = words[n] + " "
									y += lineHeight
								} else {
									context.fillText(line, x, y)
									line = words[n] + " "
									y += lineHeight
								}
							} else {
								line = testLine
							}
						}
						let pattern = /\n/;
						let result = line.search(pattern);
						if (result < 0) {
							let slash = line.split("\n")
							context.fillText(slash[0], x, y)
						} else {
							let slash = line.split("\n")
							context.fillText(slash[0], x, y)
						}
					} else {
						// eslint-disable-next-line no-unused-vars	
						line = testLine
						context.fillText(slash[i], x, y + (i * lineHeight), maxWidth, i + lineHeight)
					}
				}
			},
			wrapText(context, text, x, y, maxWidth, lineHeight) {
				let words = text.split(" ")
				let line = ""
				for (let n = 0; n < words.length; n++) {
					let testLine = line + words[n] + " "
					let metrics = context.measureText(testLine)
					let testWidth = metrics.width
					if (testWidth > maxWidth && n > 0) {
						context.fillText(line, x, y)
						line = words[n] + " "
						y += lineHeight
					} else {
						line = testLine
					}
				}
				context.fillText(line, x, y)
			},
			changeTab(index) {
				this.training[0].detail.forEach((v, i) => {
					this.training[index].detail[i].TrainingDetailPageNo = this.training[0].detail[i].TrainingDetailPageNo
					this.training[index].detail[i].TrainingDetailMainImage = this.training[0].detail[i].TrainingDetailMainImage
					v.container.forEach((v2, i2) => {
						let alias1 = this.training[0].detail[i].container[i2]
						let alias2 = this.training[index].detail[i].container[i2]
						alias2.TrainingDetailContainerNo = alias1.TrainingDetailContainerNo
						alias2.TrainingDetailContainerPosition = alias1.TrainingDetailContainerPosition
						alias2.TrainingDetailContainerType = alias1.TrainingDetailContainerType
						alias2.TrainingDetailContainerImage = alias1.TrainingDetailContainerImage
					})
				})
			},
			getPartnerName(val) {
				return this.partnerAccessList.find((v) => v.value === val)?.text
			},
			getCommoditiyName(value) {
				return this.commodities.find((v) => v.value === value)?.text
			},
			getLanguageName(value) {
				return this.languages.find((v) => v.value === value)?.text
			},
			regexPagetitle(value) {
				let rex = value.replace(/(<([^>]+)>)/ig, '')
				let pattern = /&nbsp;/g;;
				let result = rex.search(pattern);
				let regex1 = /&nbsp;/g;
				let rexfinal = rex.replace(regex1, '')
				let rexlengt = 0

				if(result > 0){
					rexlengt = rexfinal.length+1
				} else if (result <= 0) {
					rexlengt = rex.length
				}

				if(rexlengt > 60){
					this.$swal("Page Title", "Maximum Characters 60" , "info")
					return true
				}
				return false
			},
			regexValuePagetitle(value) {
				let rex = value.replace(/(<([^>]+)>)/ig, '')
				let pattern = /&nbsp;/g;;
				let result = rex.search(pattern);
				let regex1 = /&nbsp;/g;
				let rexfinal = rex.replace(regex1, '')
				let rexlengt = 0

				if( result > 0){
					rexlengt = rexfinal.length+1
				} else if (result <= 0) {
					rexlengt = rex.length
				}
				return rexlengt
			},
			makeToast(title, variant, msg) {
				this.$bvToast.toast(msg, {
					title: ` ${title || "default"}`,
					variant: variant,
					solid: true,
					toaster: "b-toaster-top-center",
				})
			},
			changeContainer(val) {
				if (val === "FULL") {
					// this.containerPositions = [
					// 	{
					// 		value: "TOP_MIDDLE",
					// 		text: "TOP",
					// 	},
					// 	{
					// 		value: "CENTER",
					// 		text: "CENTER",
					// 	},
					// 	{
					// 		value: "BOTTOM_MIDDLE",
					// 		text: "BOTTOM",
					// 	},
					// ]
				}
			},
			getPartnerAccess(id) {
				this.loadingPartner = true
				this.commodityFields.partnerAccess = []
				let payload = {
					commodityId: id,
				}
				this.$http
					.Get(payload, this.$urlApi.training.partner.get)
					.then((r) => {
						if (r.success) {
							this.loadingPartner = false
							this.partnerAccessList = r.results
						} else {
							this.$swal("Error!", r.error_massage, "error")
						}
					})
					.catch((e) => {
						this.$swal("Error!", e.response.data.error_message, "error")
					})
			},
		},
	}
</script>

<style lang="scss">
	.btn-add:hover {
		background-color: #fffffff1;
	}
	.btn-add {
		background-color: #f3f4f6f1;
	}
	.ck-editor__editable_inline {
		min-height: 6em;
	}
	.border-color {
		border-color: var(--ck-color-focus-border);
		box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px var(--ck-color-focus-border);
		outline: 0 none;
	}
	.canvas {
		font-family: Arial, Helvetica, sans-serif;
		font-size: 20px;
		text-align: center;
	}
	.form-control {
		background-color: #ffffff !important;
	}
	.image-label {
		font-style: italic;
		font-size: 95%;
		color: #ffa500;
	}
	.image-label-red {
		font-style: italic;
		font-size: 95%;
		color: #ff0000;
	}
	.vgt-wrap__footer .footer__row-count {
		font-size: 0.5rem;
	}
	.boxcanvas {
		margin-top: -165px;
		background-color: white;
	}
	.titlecanvas {
		writing-mode: vertical-rl;
		transform:scale(-1);
	}
	.bgcanvas {
		background-size: cover;
  		background-position: center;
	}
	.collapsed {
		transform: rotate(0deg) scale(1);
		/* -webkit-transform: rotate(45deg);
		-ms-transform: rotate(45deg); */
	}
	.dropdownitem-action:hover a, .dropdownitem-action:focus a{
		background-color: #CAEAFF !important;
	}
</style>
